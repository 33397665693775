.footer {
    text-align: center;
    margin: 50px 0;
}

.footer a {
    text-decoration: none;
    color: var(--ion-color-primary);
}



.iconWrapper {
    opacity: 1;
    scale: 1;
    transition: scale 0.2s, opacity 1s;
    width: 96px;
    height: 96px;
    padding: 0px;
    aspect-ratio: 1;
    margin: 0 8px 0 5px;
    /* background: rgb(255, 255, 255, 0.8); */
    background: none;
    box-shadow: none;
    margin: 0 auto;
}

.iconWrapper svg,
.iconWrapper ion-icon {
    width: 100%;
    height: 100%;
    color: var(--ion-color-primary);
}

:global(.dark) .iconWrapper svg,
:global(.dark) .iconWrapper ion-icon {
    color: #fff;
}

.iconWrapper.img {
    padding: 0;
    overflow: hidden;
    background: none;
}