.appHeader {
  z-index: 10;
  background: none;
  overflow: hidden;
}

.appHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.appHeader h1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
  font-weight: 400;
  margin: 0 auto;
  transition: none;
}

.appHeader h2 {
  margin: 0;
  letter-spacing: -0.1px;
  font-size: 0.725rem;
  font-weight: 700;
  margin: 2px 0 0 0;
}

.iconWrapper {
  opacity: 1;
  scale: 1;
  transition: scale 0.2s, opacity 1s;
  width: 36px;
  height: 36px;
  padding: 0px;
  aspect-ratio: 1;
  margin: 0 10px 0 5px;
  /* background: rgb(255, 255, 255, 0.8); */
  background: none;
  box-shadow: none;
}

.iconWrapper svg,
.iconWrapper ion-icon {
  width: 100%;
  height: 100%;
  color: var(--ion-color-primary);
}

:global(.dark) .iconWrapper svg,
:global(.dark) .iconWrapper ion-icon {
  color: #fff;
}

.iconWrapper:global(.img) {
  padding: 0;
  overflow: hidden;
  background: none;
  border-radius: 8%;
}
