.converterLabel {
  margin: 5px 0 10px 0;
  font-size: 1rem;
  font-weight: 700;
}

.titleSection {
  margin: 25px 10px 15px 10px;
}

.titleSection h1 {
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0;
}

.formulaSection {
  font-size: 2rem;
  margin-top: 10px;
}

.block {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.tvBox {
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 20px;
  z-index: 0;
}

.viewBox {
  box-shadow: 5px 5px 0 #000, 5px -5px 0 #000, -5px -5px 0 #000, -5px 5px 0 #000;
  background: #fff;
  color: #fff;
  overflow: hidden;
  margin: 0 auto;
}

:global(.dark) .viewBox {
  /* box-shadow: 5px 5px 0 #333, 5px -5px 0 #333, -5px -5px 0 #333, -5px 5px 0 #333; */
  background: #111;
  overflow: hidden;
}

.viewBoxBackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(circle at 0% 0%, rgb(255, 255, 255, 0.15), transparent 40%),
  radial-gradient(circle at 100% 100%, rgb(255, 255, 255, 0.05), transparent 30%),
  #222;
}

.diagnalText {
  content: "";
  position: absolute;
  font-size: 22px;
  font-weight: 700;
  transform-origin: 0% 0%;
  top: 50%;
  left: 50%;
  background: #222;
  padding: 2px 2px 2px 5px;
  border-radius: 5px;
}

.diagonalLine {
  position: absolute;
  width: 2000px;
  border-bottom: 2px dashed #fff;
  bottom: -1px;
  left: 0;
  transform-origin: left botttom;
  -webkit-transform-origin: left bottom;
}

.tvLeg {
  position: absolute;
  width: 40px;
  height: 100px;
  background: #000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.tvStand {
  position: absolute;
  width: 200px;
  height: 10px;
  background: #000;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  border-radius: 5px;
}
