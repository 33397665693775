.description {
  font-size: 0.725rem !important;
  line-height: 1.25em !important;
  margin: 0;
  color: var(--ion-color-step-600, #666666);
}

.tag {
  font-size: 0.8rem !important;
  line-height: 1em !important;
}

.tagItem {
  color: var(--ion-color-primary);
  border-radius: 5px;
  margin-right: 5px;
}

.appTitle {
  font-size: 1.2rem !important;
  margin: 0;
}

.appItem {
  align-items: start;
  border-radius: 5px;
  height: 100%;
}

.appItem::part(native) {
  height: 100%;
}

.appItem ion-avatar {
  --border-radius: 7.8%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
}

.iconWrapper {
  width: 64px;
  height: 64px;
  border-radius: 7.8%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
  align-self: self-start;
  margin-top: 10px;
  margin-left: 2px;
  margin-bottom: 10px;
  margin-right: 15px;
  overflow: hidden;
  padding: 5px;
}

.iconWrapper.svg {
  padding: 8px;
}

.iconWrapper.ionIcon {
  padding: 15px;
}

.iconWrapper svg,
.iconWrapper ion-icon {
  width: 100%;
  height: 100%;
  color: var(--ion-color-primary);
}



:global(body.dark) .iconWrapper svg,
:global(body.dark) .iconWrapper ion-icon {
  color: #fff;
}

:global(body.dark) .iconWrapper {
  background: #88888844;
}

.appItem ion-label {
  padding: 10px 0;
}