.list{
    --ion-item-background: transparent;
}

.list ion-icon{
    background: rgb(var(--ion-color-primary-rgb), 0.2);
    padding: 10px;
    border-radius: 8px;
    margin: 5px 10px 5px 0;
}

ion-modal{
    /* --height: auto; */
    /* --width: auto; */
}

.colorButtons ion-button {
    width: 32px;
    height: 32px;
}