:root {
  --ion-font-family: "Segoe UI Variable Display", "Segoe UI", "Roboto",
    "Helvetica Neue", sans-serif;
  --mono-font: "Cascadia Mono", "SF Mono", "Roboto Mono", Consolas, monospace;
}

/* 
body {
  --ion-background-color: linear-gradient(
    180deg,
    rgb(var(--ion-color-primary-rgb), 0.05) 18.22%,
    rgb(var(--ion-color-primary-rgb), 0.1) 100%
  );

  --ion-toolbar-background: rgb(var(--ion-color-primary-rgb), 0.05);
  --ion-item-background: rgb(255, 255, 255, 0.9);
}

body.dark {
  --ion-background-color: linear-gradient(
    180deg,
    rgb(var(--ion-color-primary-rgb), 0.3) 18.22%,
    rgb(var(--ion-color-primary-rgb), 0.1) 100%
  ) !important;
  --ion-toolbar-background: rgb(var(--ion-color-primary-rgb), 0.3) !important;
  --ion-item-background: rgb(var(--ion-color-primary-rgb), 1);
} */

.layoutBorder {
  background: rgba(var(--ion-color-primary-rgb), 0.03);
  border: 1px solid rgba(var(--ion-color-primary-rgb), 0.1);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
}

.dark .layoutBorder {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
}

ion-toolbar {
  --background: linear-gradient(
      rgba(var(--ion-color-primary-rgb), 0.1),
      rgba(var(--ion-color-primary-rgb), 0.1)
    ),
    linear-gradient(#fff, #fff);
  /* --background: linear-gradient(rgba(var(--ion-color-primary-rgb), 0.05),
      rgba(var(--ion-color-primary-rgb), 0.05)), linear-gradient(#fff, #fff); */
  /* --color: #fff; */
  /* --ion-toolbar-color: #000; */
  /* --background: none; */
}

body.dark ion-toolbar {
  --background: linear-gradient(
      rgba(var(--ion-color-primary-rgb), 0.12),
      rgba(var(--ion-color-primary-rgb), 0.1)
    ),
    linear-gradient(#000, #000);
  /* --background: none; */
}

ion-content {
  background: #fff;
}

body.dark ion-content {
  background: #111111;
}

ion-content::part(background) {
  background: linear-gradient(
      180deg,
      rgb(var(--ion-color-primary-rgb), 0.1) 18.22%,
      rgb(var(--ion-color-primary-rgb), 0.02) 100%
    ),
    linear-gradient(#fff, #fff) !important;
}

body.dark ion-content::part(background) {
  background: linear-gradient(
      180deg,
      rgb(var(--ion-color-primary-rgb), 0.2) 18.22%,
      rgb(var(--ion-color-primary-rgb), 0.1) 100%
    ),
    linear-gradient(#000, #000) !important;
}

ion-card-content {
  color: #111;
}

body.dark ion-card-content {
  color: #eee;
}

.ion-content-scroll-host {
  position: absolute;
  top: var(--ion-safe-area-top);
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.ion-content-safe {
  position: absolute;
  top: var(--ion-safe-area-top);
}

ion-menu {
  margin-top: var(--ion-safe-area-top);
}

/** Maximize IonGrid for max-width to 1140px */
ion-grid.grid-fixed {
  max-width: 1140px !important;
  width: 100% !important;
}
