.appPage ion-input {
  font-size: x-large;
  --placeholder-color: #888;
}

.appHeader {
  margin: 2rem 0 1rem 0;
}

.appHeader .appHeaderContainer {
  align-items: center;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.appHeader h1 {
  font-size: 1.3rem;
  line-height: 1.3rem;
  font-weight: 400;
  margin: 0 auto;
  transition: none;
}

.appHeader h2 {
  font-size: 1rem;
  font-weight: 700;
  margin: 2px 0 0 0;
}


/*** Icon Wrapper ***/

.appHeader .iconWrapper {
  width: auto;
  height: 80px;
  aspect-ratio: 1 !important;
  border-radius: 7.8%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  background: linear-gradient(rgba(var(--ion-color-primary-rgb), 70%),
      rgba(var(--ion-color-primary-rgb), 50%)),
    linear-gradient(#222, #222);
  padding: 4px;
  margin: 10px 0;
  opacity: 1;
  scale: 1;
  transition: opacity 0.5s ease-in;
}

.appHeader .iconWrapper.sticky svg,
.appHeader .iconWrapper.sticky ion-icon {
  color: var(--ion-color-primary);
}

.dark .appHeader .iconWrapper.sticky svg,
.dark .appHeader .iconWrapper.sticky ion-icon {
  color: #fff;
}

.appHeader .iconWrapper.img {
  padding: 0;
  overflow: hidden;
  background: none;
}

.appHeader .iconWrapper svg,
.appHeader .iconWrapper ion-icon {
  width: 100%;
  height: 100%;

  color: #fff;
}