/*** Theme 1 ***/
.table-theme-1,
.table-theme-2 {
  --color-primary-rgb: var(--ion-color-primary-rgb);
  --color-primary-ld: var(--ion-color-primary-tint);
  --color-wdg-80: rgba(255, 255, 255, 80%);
  overflow: auto;
}

.dark .table-theme-1,
.dark .table-theme-2 {
  --color-primary-ld: var(--ion-color-primary-shade);
  --color-wdg-80: rgba(50, 50, 50, 0.8);
}

/*** Theme 1 ***/
table.table-theme-1,
.table-theme-1 table {
  background: linear-gradient(
    0deg,
    rgba(var(--color-primary-rgb), 0.2),
    rgba(var(--color-primary-rgb), 0.1)
  );
  /* box-shadow: var(--layout-box-shadow); */
  width: 100%;
}

.table-theme-1 thead {
  background: rgba(var(--color-primary-rgb), 0.3);
}

.table-theme-1 tr td {
  border-top: 1px solid var(--color-primary-ld);
}

.table-theme-1 tr th,
.table-theme-1 tr td {
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
}

/*** Theme 2 ***/
table.table-theme-2,
.table-theme-2 table {
  background: linear-gradient(
    0deg,
    rgba(var(--color-primary-rgb), 0.2),
    rgba(var(--color-primary-rgb), 0.05)
  );
  width: 100%;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  border: 0.5px solid var(--color-primary-ld);
  overflow: hidden;
}

.table-theme-2 thead {
  background: rgba(var(--color-primary-rgb), 0.2);
}

.table-theme-2 tr th,
.table-theme-2 tr td {
  border: 0.5px solid var(--color-primary-ld);
}

.table-theme-2 tr th,
.table-theme-2 tr td {
  padding: 0.25rem 0.25rem 0.25rem 0.75rem;
}

.table-theme-2 tr:last-child td:first-child {
  border-radius: 0 0 0 3px;
}

.table-theme-2 tr:last-child td:last-child {
  border-radius: 0 0 3px 0;
}

.table-theme-2 tr:first-child th:first-child {
  border-radius: 3px 0 0 0;
}

.table-theme-2 tr:first-child th:last-child {
  border-radius: 0 3px 0 0;
}

.table-theme-1 tbody tr:nth-child(even),
.table-theme-2 tbody tr:nth-child(even) {
  background: var(--color-wdg-80);
}
