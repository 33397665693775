.tvBox {
  position: relative;
  font-size: 12px;
  padding: 15px;
}

.viewBox {
  /* box-shadow: 10px 10px 0 var(--ion-color-primary), 10px -10px 0 var(--ion-color-primary), -10px -10px 0 var(--ion-color-primary), -10px 10px 0 var(--ion-color-primary); */
  /* border: 1px solid var(--ion-color-primary); */
  box-shadow: 0 0 0 2px var(--ion-color-primary);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 2px;
}
:global(.dark) .viewBox {
  /* box-shadow: 5px 5px 0 #333, 5px -5px 0 #333, -5px -5px 0 #333, -5px 5px 0 #333; */
  overflow: hidden;
}

.diagnalText {
  content: "";
  position: absolute;
  font-size: 18px;
  transform-origin: 0% 0%;
  top: calc(50% - 10px);
  left: calc(50% - 5px);
  padding: 2px 5px;
  border-radius: 5px;
  white-space: nowrap;
  line-height: 1em;
  /* background: var(--ion-color-primary); */
  /* color: #fff; */
}

.diagonalLine {
  position: absolute;
  width: 2000px;
  /* border-bottom: 2px dashed var(--ion-color-primary); */
  box-shadow: 0 0 0 1px rgb(var(--ion-color-primary-rgb), 0.5);
  bottom: 0;
  left: 0;
  transform-origin: left botttom;
  -webkit-transform-origin: left bottom;
}
