/*** Result Section ***/
.resultBox .resultTitle {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  color: #000000;
  line-height: 1em;
  margin: 1rem 0;
}

.resultBox .resultTitle.large {
  font-size: 4rem;
}

.resultBox .resultTitle.small {
  font-size: 2rem;
}

.dark .resultBox .resultTitle {
  color: #fff;
}


.resultBox .resultTitle span {
  font-size: 2rem;
  opacity: 0.7;
  font-weight: 400;
}

.resultBox .resultTitle span.large {
  font-size: 2.5rem;
}

.resultBox .resultTitle span.small {
  font-size: 1.5rem;
}

.resultBox .resultIcon {
  text-align: center;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px auto;
}

.resultBox .resultIcon svg,
.resultBox .resultIcon ion-icon {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  color: #000;
}

.dark .resultBox .resultIcon svg,
.dark .resultBox .resultIcon ion-icon {
  color: #fff;
}

.resultBox .resultSubtitle {
  font-size: 1.2rem;
}

.resultBox .resultText {
  font-size: 1rem;
  color: var(--ion-color-medium);
}

.resultWithIcon {
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin: 10px 0;
}

.resultWIIcon {
  border-radius: 5px 0 0 5px;
  align-items: center;
  display: flex;
  background: var(--ion-color-primary);
  color: #fff;
  border: 2px solid var(--ion-color-primary);
  width: 80px;
  padding: 5px;
  justify-content: center;
}

.resultWIIcon>ion-icon,
.resultWIIcon>svg {
  width: 100%;
  height: 100%;
}

.resultWIText {
  font-size: 3rem;
  border: 2px solid var(--ion-color-primary);
  /* border-left: none; */
  border-radius: 0 5px 5px 0;
  padding: 0 25px;
  flex-grow: 1;
}