.expandableRange ion-range {
  opacity: 0;
  height: 0;
  width: 0;
  transform: scaleY(0);
  transition: all 300ms;
  background: rgb(var(--ion-color-primary-rgb), 0.05);
  border-radius: 5px;
}

.dark .expandableRange ion-range {
  background: rgb(255, 255, 255, 0.05);
}

.expandableRange:focus-within ion-range {
  width: 100%;
  height: auto;
  opacity: 1;
  transform: scaleY(1);
  transition: all 300ms;
  margin: 4px 0;
}

.cardHeader {

  background: rgb(var(--ion-color-primary-rgb), 0.2);
}

.cardContent {
  padding: 8px 16px 0 16px;
  font-size: 1rem;
  line-height: 1.5em;
  color: #222;
}


.cardTitle {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000;
  margin: 0;
  padding: 12px 16px;
}

.dark .cardTitle,
.dark .cardContent {
  color: #fff;
}

.cardSubtitle {
  font-size: 1rem;
  font-weight: 700;
}